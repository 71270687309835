import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';

import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonServiceModule } from './common/Http.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { Ng2TelInputModule } from 'ng2-tel-input';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CommonServiceModule,
		// NgxIntlTelInputModule,
		RouterModule.forRoot(routes, {
			useHash: false,
			preloadingStrategy: PreloadAllModules
		})
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
