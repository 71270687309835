import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class HttpService {
	private defaultUrl: string;
	gatewayUrl: string;

	constructor(private http: HttpClient) { 
		this.gatewayUrl = 'https://gateway.parrot.solutions/index.php';
		// this.defaultUrl = 'https://initmail.herokuapp.com/';
		this.defaultUrl = 'http://devlms.parrot.solutions:9753/';
	}

	public post(info: any): any {
		if (this.preCheck(info, false)) {
			return this.http.post(this.gatewayUrl, info.body, { headers: info.options });
		} else {
			return Observable.throw('apiKey missing');
		}
	}
	private preCheck(info: any, header): any {
		// const account = this._localStorageService.getLocalStorage('account');
		// const pAccount = this._localStorageService.getLocalStorage('pAccount');
		if (info) {
			const headers: HttpHeaders = new HttpHeaders()
				.set('Accept', 'application/json')
				.set('Content-Type', 'application/json');
			// if (account) {
			// 	info.body.session_id = account.session_id ;
			// }
			// if (pAccount) {
			// 	info.body.session_id = pAccount.session_id ;
			// }
			info.options = headers;
			info.body.url = this.defaultUrl + info.body.url;
			return true;
		} else {
			return false;
		}
	}
	// public get(info: any): any {
	// 	if (this.preCheck(info, false)) {
	// 		return this.http.get(info.url, info.options)
	// 			.map(this.extractData)
	// 			.catch(this.handleError);
	// 	} else {
	// 		return Observable.throw('missing');
	// 	}
	// }

	// public post(info: any): any {
	// 	console.log('Service', info);
	// 	if (this.preCheck(info, false)) {
	// 		return this.http.post(info.url, info.body, info.options)
	// 			.map(this.extractData)
	// 			.catch(this.handleError);
	// 	} else {
	// 		return Observable.throw('missing');
	// 	}
	// }

	// public put(info: any): any {
	// 	if (this.preCheck(info, false)) {
	// 		return this.http.put(info.url, info.body, info.options)
	// 			.map(this.extractData)
	// 			.catch(this.handleError);
	// 	} else {
	// 		return Observable.throw('missing');
	// 	}
	// }

	// public delete(info: any): any {
	// 	if (this.preCheck(info, false)) {
	// 		return this.http.delete(info.url, info.options)
	// 			.map(this.extractData)
	// 			.catch(this.handleError);
	// 	} else {
	// 		return Observable.throw('missing');
	// 	}
	// }

	// private preCheck(info: any, isXMLHttpRequest: boolean): any {
	// 	const headers: Headers = new Headers({ 'Content-Type': 'application/json' });
    //     const options = new RequestOptions({ headers });
    //     info.options = options;

    //     info.url = this.defaultUrl + info.url;
    //     return true;
	// }

	// private handleError(error: Response): any {
	// 	console.error('Error', error.status, error.json());
	// 	const body = error.json();
	// 	if (error.status === 401) {
    //         console.log('error status 401');
	// 	}
	// 	return Observable.throw(body.TransactionMessage);
	// }

	// private extractData(res: Response): any {
	// 	const body = res.json();
	// 	console.log('extractData', body);
	// 	return body || {};
	// }

}

// tslint:disable-next-line:max-classes-per-file
@NgModule({
	providers: [HttpService],
	imports: [HttpClientModule]
})
export class CommonServiceModule {
}
